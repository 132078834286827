@if (notification) {
<div class="py-3 px-2 mb-2 notif-item cursor-pointer border-round shadow-0 flex align-items-start {{type}}"
  [ngStyle]="{ 'background-color': getBackgroundColor(), 'border-color': getBackgroundColor() }">

  <div><span class="text-900 text-md font-medium mb-1 flex align-items-center gap-1">
      <div class="icon-wrapper  border-round inline-flex align-items-center justify-content-center mr-1"
        [ngStyle]="{ 'background-color': getSecondaryColor() }">
        <i class="text-xs" [ngClass]="getNotificationIcon()"></i>
      </div>
      {{notification.title}}
    </span>
    <p class="mt-1 mb-1 text-600 font-medium text-sm">
      {{notification.paragraphText}}
    </p>
    @if (this.notification.type === 'package') {
    <p class="mt-3 mb-0 text-600 font-medium text-sm">
      <a class="cursor-pointer link border-4"><span>Extend
          Package</span></a>
    </p>
    }
    @if (this.notification.type === 'package-expired') {
    <p class="mt-3 mb-0 text-600 font-medium text-sm">
      <a class="cursor-pointer link border-4"><span>Buy
          Package</span></a>
    </p>
    }

  </div>
  @if (this.notification.type !== 'package') {
  <!-- <div class="ml-auto flex flex-column align-items-end">
    <p-button [link]="true" size="small" (click)="menu.toggle($event)" icon="pi pi-ellipsis-v"
      styleClass="p-0 w-1rem text-500" />
    <p-menu #menu appendTo="body" [popup]="true" [model]="items"></p-menu>
    <span class="text-400 text-xs"> 14h </span>
  </div> -->
  }
</div>
}